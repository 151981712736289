import React, {lazy, useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import { getRoute } from "./language_support";

import PrivateRoute from "./PrivateRoute";

import ErrorBoundaryPage from "../component/ErrorBoundary/ErrorBoundaryPage";
import {ToolBoxDataType} from "../interfaces/ToolBoxData";
import {Restriction} from "../interfaces/Restriction";

import AuthLoadingPage from '../Pages/AuthLoading/AuthLoadingPage';
import ChangePassword from "../Pages/ChangePassword/ChangePassword";
import {CoachingToolPage} from "../Pages/CoachingTool.tsx/CoachingTool";
import ContactForm from "../Pages/ContactForm/ContactForm";
import {DynamicPage} from "../Pages/DynamicPage/DynamicPage";
import ForgotPasswordPage from "../Pages/ForgotPassword/ForgotPasswordPage";
import {GoalSettingWrapper} from "../Pages/Toolbox/Goal/GoalSettingsWrapper";
import {HomePage} from "../Pages/Home/HomePage";
import Login from "../Pages/Login/Login";
import MyProgressPage from "../Pages/Toolbox/MyProgress/MyProgressPage";
import {NotFoundPage} from "../Pages/NotFound/NotFoundPage";
import OrderKitForm from "../Pages/OrderKitForm/OrderKitForm";
import RegisterFormHCP from "../Pages/Register/RegisterFormHCP";
import RegisterFormMain from "../Pages/Register/RegisterFormMain";
import RegisterFormPatient from "../Pages/Register/RegisterFormPatient";
import {ReminderPage} from "../Pages/Toolbox/Reminder/ReminderPage";
import ThankYouForRegisteringPage from "../Pages/Register/ThankYouForRegisteringPage";
import ThankYouPage from "../Pages/Coolbag/ThankYouPage";
import ToolBoxSettingsPage from "../Pages/Toolbox/ToolBoxSettingsPage";
import {YourConditionPage} from "../Pages/YourCondition.tsx/YourConditionPage";

const AccountPage = lazy(() => import("../Pages/Profile/ProfilePage"));
const CoolbagForm = lazy(() => import("../Pages/Coolbag/CoolbagForm"));

export const Routes: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <ErrorBoundaryPage>
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

        <Route exact path="/">
          <Redirect to={getRoute('home')} />
        </Route>
        
        <Route path={getRoute('home')}>
          <HomePage />
        </Route>
      
        <Route path={getRoute('loginWithType')}>
          <Login />
        </Route>
        
        <Route path={getRoute('login')}>
          <Login />
        </Route>
        
        <Route path={getRoute('forgotPassword')}>
          <ForgotPasswordPage />
        </Route>
        
        <Route path={getRoute('changePassword')}>
          <ChangePassword />
        </Route>
        
        <Route exact path={getRoute('authLoader')}>
          <AuthLoadingPage />
        </Route>        
        
        <Route path={getRoute('register')}>
          <RegisterFormMain />
        </Route>
        
        <Route path={getRoute('patientRegister')}>
          <RegisterFormPatient />
        </Route>
        
        <Route path={getRoute('patientThankYou')}>
          <ThankYouForRegisteringPage breadcrumb="For patients" />
        </Route>
        
        <Route path={getRoute('hcpRegister')}>
          <RegisterFormHCP />
        </Route>
        
        <Route path={getRoute('hcpThankYou')}>
          <ThankYouForRegisteringPage breadcrumb="For HCPs" />
        </Route>
        
        <PrivateRoute path={getRoute('profile')} allowWithDocCheck={true}>
          <AccountPage />
        </PrivateRoute>
        
        <Route exact path={getRoute('yourCondition')}>
          <YourConditionPage />
        </Route>        
        
        <Route path={getRoute('coachingTool')}>
          <CoachingToolPage />
        </Route>
        
        <PrivateRoute exact path={getRoute('hcpMakeRequestRepCall')} restriction={Restriction.HCP}>
          <ContactForm />
        </PrivateRoute>
        
        <PrivateRoute exact path={getRoute('hcpRequestRepCall')} restriction={Restriction.HCP}>
          <ContactForm />
        </PrivateRoute>
        
        <PrivateRoute exact path={getRoute('hcpOrderKitIdacio')} restriction={Restriction.HCP}>
          <OrderKitForm />
        </PrivateRoute>
        
        <PrivateRoute exact path={getRoute('hcpOrderKitTyenne')} restriction={Restriction.HCP}>
          <OrderKitForm />
        </PrivateRoute>
        
        <PrivateRoute path={getRoute('hcpAccess')} restriction={Restriction.HCP}>
          <DynamicPage />
        </PrivateRoute>
        
        
        {/*IDACIO*/}
        <PrivateRoute path={getRoute('idacioUnderstanding')} restriction={Restriction.IDACIO}>
          <DynamicPage/>
        </PrivateRoute>

        <PrivateRoute path={getRoute('idacioHowToInject')} restriction={Restriction.IDACIO}>
          <DynamicPage/>
        </PrivateRoute>

        <PrivateRoute path={getRoute('idacioCaring')} restriction={Restriction.IDACIO}>
          <DynamicPage/>
        </PrivateRoute>

        <PrivateRoute path={getRoute('idacioHelp')} restriction={Restriction.IDACIO}>
          <DynamicPage/>
        </PrivateRoute>

        <PrivateRoute exact path={getRoute('idacioGoalsSettings')} restriction={Restriction.IDACIO}>
          <GoalSettingWrapper editPageUrl={getRoute('idacioGoalsSettingsGoal')} />
        </PrivateRoute>

        <PrivateRoute path={getRoute('idacioGoals')} restriction={Restriction.IDACIO}>
          <GoalSettingWrapper editPageUrl={getRoute('idacioGoalsSettingsGoal')} />
        </PrivateRoute>

        <Redirect
            from={getRoute('idacioGoals')}
            to={getRoute('idacioGoalsSettings')}
        />

        <PrivateRoute
            exact
            path={getRoute('idacioLogMedicationSettings')}
            restriction={Restriction.IDACIO}>
          <ToolBoxSettingsPage
              dataType={ToolBoxDataType.MEDICATIONS}
              editPageUrl={getRoute('idacioLogMedicationSettingsMedication')}
              showTime={true}
              showDate={true}
              isMedication={true}
          />
        </PrivateRoute>

        <PrivateRoute path={getRoute('idacioReminders')} restriction={Restriction.IDACIO}>
          <ReminderPage />
        </PrivateRoute>

        <PrivateRoute exact path={getRoute('idacioProgress')} restriction={Restriction.IDACIO}>
          <MyProgressPage
            goalsLink={getRoute('idacioGoalsSettings')}
            medicationsLink={getRoute('idacioLogMedicationSettings')}
            reminderLink={getRoute('idacioReminders')}/>
        </PrivateRoute>

        <PrivateRoute path={getRoute('idacioCoolbagThankYou')} restriction={Restriction.IDACIO}>
          <ThankYouPage />
        </PrivateRoute>

        <PrivateRoute path={getRoute('idacioCoolbag')} restriction={Restriction.IDACIO}>
          <CoolbagForm />
        </PrivateRoute>

        <PrivateRoute path={getRoute('idacio')} restriction={Restriction.IDACIO}>
          <DynamicPage />
        </PrivateRoute>

        {/*TYENNE*/}
        <PrivateRoute path={getRoute('tyenneUnderstanding')} restriction={Restriction.TYENNE}>
          <DynamicPage />
        </PrivateRoute>

        <PrivateRoute path={getRoute('tyenneHowToInject')} restriction={Restriction.TYENNE}>
          <DynamicPage />
        </PrivateRoute>

        <PrivateRoute path={getRoute('tyenneCaring')} restriction={Restriction.TYENNE}>
          <DynamicPage />
        </PrivateRoute>

        <PrivateRoute path={getRoute('tyenneHelp')} restriction={Restriction.TYENNE}>
          <DynamicPage />
        </PrivateRoute>

        <PrivateRoute path={getRoute('tyenneGoals')} restriction={Restriction.TYENNE}>
          <GoalSettingWrapper editPageUrl={getRoute('tyenneGoalsSettingsGoal')} />
        </PrivateRoute>

        <PrivateRoute exact path={getRoute('tyenneGoalsSettings')} restriction={Restriction.TYENNE}>
          <GoalSettingWrapper editPageUrl={getRoute('tyenneGoalsSettingsGoal')} />
        </PrivateRoute>

        <Redirect
            from={getRoute('tyenneGoals')}
            to={getRoute('tyenneGoalsSettings')}
        />

        <PrivateRoute
            exact
            path={getRoute('tyenneLogMedication')}
            restriction={Restriction.TYENNE}>
          <ToolBoxSettingsPage
              dataType={ToolBoxDataType.MEDICATIONS}
              editPageUrl={getRoute('tyenneLogMedicationSettingsMedication')}
              showTime={true}
              showDate={true}
              isMedication={true}
          />
        </PrivateRoute>

        <PrivateRoute path={getRoute('tyenneReminders')} restriction={Restriction.TYENNE}>
          <ReminderPage />
        </PrivateRoute>

        <PrivateRoute exact path={getRoute('tyenneProgress')} restriction={Restriction.TYENNE}>
          <MyProgressPage
            goalsLink={getRoute('tyenneGoalsSettings')}
            medicationsLink={getRoute('tyenneLogMedication')}
            reminderLink={getRoute('tyenneReminders')}/>
        </PrivateRoute>

        <PrivateRoute path={getRoute('tyenneCoolbagThankYou')} restriction={Restriction.TYENNE}>
          <ThankYouPage />
        </PrivateRoute>

        <PrivateRoute path={getRoute('tyenneCoolbag')} restriction={Restriction.TYENNE}>
          <CoolbagForm />
        </PrivateRoute>

        <PrivateRoute path={getRoute('tyenne')} restriction={Restriction.TYENNE}>
          <DynamicPage />
        </PrivateRoute>
      
        <PrivateRoute path={getRoute('myTreatment')} restriction={Restriction.PATIENT}>
          <DynamicPage />
        </PrivateRoute>        
        
        <Route path="">
          <DynamicPage />
        </Route>        
        
        <Route path={getRoute('notFound')}>
          <NotFoundPage />
        </Route>        
        

      </Switch>
    </ErrorBoundaryPage>
  );
};