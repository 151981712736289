import { RouteConfig } from './types';

export const hrRoutes: RouteConfig = {
  home: '/home',
  loginWithType: '/prijavite-se/:userType',
  login: '/prijavite-se',
  forgotPassword: '/zaboravili-lozinku',
  changePassword: '/change-password',
  register: '/registracija',
  patientRegister: '/pacijent/registracija',
  patientThankYou: '/pacijent/hvala',
  hcpRegister: '/hcp/registracija',
  hcpThankYou: '/hcp/hvala',
  profile: '/profiel',
  yourCondition: '/početni-koraci/vaša-bolest',
  
  coachingTool: '/wellbeing-and-support/your-wellbeing/coaching-tool',
  
  hcpMakeRequestRepCall: '/hcp-access/make-a-request/request-a-rep-call',
  hcpRequestRepCall: '/hcp-access/request-a-rep-call',
  hcpOrderKitIdacio: '/hcp-access/make-a-request/order-a-kit-idacio',
  hcpOrderKitTyenne: '/hcp-access/make-a-request/order-a-kit-tyenne',
  dynamic: '',
  hcpAccess: '/hcp-access',
  
  myTreatment: '/moje-liječenje',
  idacioUnderstanding: '/moj-idacio/razumijevanje-liječenja-idacio',
  idacioHowToInject: '/moj-idacio/injiciranje-lijeka-idacio',
  idacioCaring: '/moj-idacio/postupanje-s-lijekom',
  idacioHelp: '/moj-idacio/pomoć-i-podrška',
  idacioGoalsSettings: '/moj-idacio/moj-napredak/ciljeve/postavke',
  idacioGoals: '/moj-idacio/moj-napredak/ciljeve',
  idacioGoalsSettingsGoal: '/moj-idacio/moj-napredak/ciljeve/postavke/cilj',
  idacioLogMedicationSettings: '/moj-idacio/moj-napredak/dnevnika-lijekova/postavke',
  idacioLogMedicationSettingsMedication: '/moj-idacio/moj-napredak/dnevnika-lijekova/postavke/lijekova',
  idacioReminders: '/moj-idacio/moj-napredak/podsjetnici',
  idacioProgress: '/moj-idacio/moj-napredak',
  idacioCoolbagThankYou: '/moj-idacio/zatraži-rashladnu-torbu/hvala',
  idacioCoolbag: '/moj-idacio/rashladnu-torbu',
  idacio: '/moj-idacio',
  
  tyenneUnderstanding: '/moj-tyenne/razumijevanja-lijeka-tyenne',
  tyenneHowToInject: '/moj-tyenne/kako-injicirati-tyenne',
  tyenneCaring: '/moj-tyenne/briga-o-lijeku-tyenne',
  tyenneHelp: '/moj-tyenne/pomoć-i-podrška-tyenne',
  tyenneGoals: '/moj-tyenne/moj-napredak/ciljeve',
  tyenneGoalsSettings: '/moj-tyenne/moj-napredak/ciljeve/postavke',
  tyenneGoalsSettingsGoal: '/moj-tyenne/moj-napredak/ciljeve/postavke/cilj',
  tyenneLogMedication: '/moj-tyenne/moj-napredak/dnevnika-lijekova/postavke',
  tyenneLogMedicationSettingsMedication: '/moj-tyenne/moj-napredak/dnevnika-lijekova/postavke/medication',
  tyenneReminders: '/moj-tyenne/moj-napredak/podsjetnici',
  tyenneProgress: '/moj-tyenne/moj-napredak',
  tyenneCoolbagThankYou: '/moj-tyenne/zatraži-rashladnu-torbu/hvala',
  tyenneCoolbag: '/moj-tyenne/rashladnu-torbu',
  tyenne: '/moj-tyenne',
};