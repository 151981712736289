import DateFnsUtils from "@date-io/date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import { default as React, Fragment, useEffect, useRef } from "react";
import { Controller, useForm, /*UseFormClearErrors,*/ UseFormSetError } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import "../../assets/scss/formElements/Calendar.scss";
import "../../assets/scss/formElements/Input.scss";
import "../../assets/scss/formElements/Switch.scss";
import { ActionType } from "../../interfaces/ActionType";
import { FormComponent } from "../../interfaces/FormComponent";
import {
  FormStructure,
  SelectType,
  TextFieldType,
} from "../../interfaces/FormStructure";
import { ToolBoxData } from "../../interfaces/ToolBoxData";
import GoogleAutocomplete from "../../Pages/Coolbag/AutoComplete";
import CalendarIcon from "../../resources/images/icon/calendar.svg";
import { toolBoxState } from "../../store/Reducer";
import ButtonWithLoader from "../ButtonWithLoader";
import "../CheckBox/CheckBox.scss";
import CustomInput from "../Input/CustomInput";
import CustomTextArea from "../Input/CustomTextarea";
import ResponseHandler from "../ResponseHandler";
import "../Select/Select.scss";
import { SelectDropdown } from "../SelectDropdown/SelectDropdown";
import * as yup from "yup";
import { ObjectShape, OptionalObjectSchema, TypeOfShape } from "yup/lib/object";
import { useTranslation } from "react-i18next";
import { clearFormErrors } from "../../store/ToolBoxActions";

const useStyles = makeStyles((theme) => ({
  checkBoxLabel: {
    lineHeight: "140%",
  },
  checkBox: {
    margin: "8px 0 0 0",
  },
}));

interface DynamicFormProps {
  formStructure?: FormStructure;
  actionType?: ActionType;
  classes?: any;
  onSubmit: (data: Object, setError?: UseFormSetError<any>) => void;
  formClassName?: any;
  children?: any;
  renderButton?: (onSubmit) => JSX.Element;
  loading?: boolean;
  success?: boolean;
  getFormStructure?: any;
  schema?: OptionalObjectSchema<
    ObjectShape,
    Record<string, any>,
    TypeOfShape<ObjectShape>
  >;
  watchFields?: Array<string>;
}

const DynamicForm = ({
  formStructure,
  actionType,
  onSubmit,
  children,
  formClassName,
  renderButton,
  loading = false,
  success = false,
  getFormStructure,
  schema,
  watchFields = [],
}: DynamicFormProps) => {
  const optionalSchema = schema || yup.object();

  const {
    handleSubmit,
    control,
    setValue,
    //getValues,
    watch,
    setError,
    formState,
  } = useForm<any>({
    resolver: formStructure?.schema
      ? yupResolver(formStructure?.schema)
      : yupResolver(optionalSchema),
  });

  const watchedFields = watch(watchFields);
  const { formErrors, error, msg }: ToolBoxData = useSelector(toolBoxState);
  const classes = useStyles();
  const dispatch = useDispatch()
  const dateRef = useRef<HTMLDivElement>(null);
  const timeRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const form = getFormStructure
    ? getFormStructure(watchedFields)
    : formStructure;

  const getError = (fieldName) => {
    if (formErrors && formErrors[fieldName]) {
      return formErrors[fieldName]
        .map((field) => field.error_title)
        .join(" , ");
    }

    return "";
  };
  
  useEffect(() => {
    dispatch(clearFormErrors());
  }, []);

  useEffect(() => {
    if(Object.keys(formState.errors).length !== 0 && (msg !== "" || error !== "") || error === "Internal error"){
      dispatch(clearFormErrors())
    }
  }, [formState,dispatch,error,msg])

  const renderField = (field, idx) => {
    const { name, allowedActions, component, placeholder, type, label } = field;

    if (allowedActions && !allowedActions.includes(actionType)) {
      return <></>;
    }

    switch (component) {
      case FormComponent.PlainText:
        return (
          <Fragment key={name}>
            <div
              className="form-input plain-text"
              id={"textfield_" + name}
              placeholder={placeholder}
              {...field}
            >
              {name}
            </div>
            <FormHelperText id={"textfield_helpertext_" + name} error>
              {getError(name)}
            </FormHelperText>
          </Fragment>
        );

      case FormComponent.TextField:
      case FormComponent.HiddenTextField:
        const textField: TextFieldType = field;

        return (
          <Controller
            render={({
              field: controlProps,
              fieldState: { error, ...fieldState },
            }) => (
              <div>
                <CustomInput
                  className="form-input"
                  type={type}
                  id={"textfield_" + name}
                  fullWidth
                  {...textField}
                  {...fieldState}
                  {...controlProps}
                />
                <FormHelperText id={"textfield_helpertext_" + name} error>
                  {t(error?.message || "") || getError(name)}
                </FormHelperText>
              </div>
            )}
            defaultValue={field.defaultValue}
            name={name}
            control={control}
            rules={{ required: true }}
            key={name}
          />
        );
      case FormComponent.TextArea:
        return (
          <Controller
            key={name}
            render={({
              field: controlProps,
              fieldState: { invalid, isDirty, isTouched, error },
            }) => (
              <div className="textarea-container-outer">
                <CustomTextArea
                  className="textarea"
                  fullWidth
                  {...controlProps}
                  {...field}
                />
                <FormHelperText id={"textfield_helpertext_" + name} error>
                  {t(error?.message || "") || getError(name)}
                </FormHelperText>
              </div>
            )}
            defaultValue={field.defaultValue}
            name={name}
            control={control}
            rules={{ required: true }}
          />
        );
      case FormComponent.Select:
        let selectClasses = ["select-container", "select-grid"];
        if (!!getError(name)) {
          selectClasses = [...selectClasses, "has-error"];
        }

        const { options }: SelectType = field;
        return (
          <Controller
            key={name}
            render={({
              field: controlProps,
              fieldState: { invalid, isDirty, isTouched, error },
            }) => (
              <div>
                <TextField
                  id={"select_" + name}
                  select
                  className={selectClasses.join(" ")}
                  variant="outlined"
                  margin="normal"
                  helperText={getError(name)}
                  label={field.label}
                  disabled={field.disabled}
                  {...controlProps}
                  SelectProps={{
                    IconComponent: () => (
                      <ExpandMoreIcon className="Select__Icon" />
                    ),
                  }}
                >
                  {options.map((val, index) => {
                    return (
                      <MenuItem value={val.id} key={val.id}>
                        {val.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <FormHelperText id={"select_helpertext_" + name} error>
                  {t(error?.message || "") || getError(name)}
                </FormHelperText>
              </div>
            )}
            defaultValue={field.defaultValue}
            control={control}
            name={name}
          />
        );

      case FormComponent.SelectDropdown:
        return (
          <Controller
            key={name}
            render={({
              field: controlProps,
              fieldState: { invalid, isDirty, isTouched, error },
            }) => (
              <div>
                <SelectDropdown
                  label={label}
                  className={"select-grid"}
                  {...field}
                  {...controlProps}
                  onChange={(value) => setValue(name, value)}
                />
                <FormHelperText id={"select_dropdown_helpertext_" + name} error>
                  {t(error?.message || "") || getError(name)}
                </FormHelperText>
              </div>
            )}
            defaultValue={field.defaultValue}
            control={control}
            name={name}
          />
        );
      case FormComponent.DatePicker:
        return (
          <Controller
            render={({
              field: controlProps,
              fieldState: { invalid, isDirty, isTouched, error },
            }) => (
              <DatePicker
                key={name}
                onChange={controlProps.onChange}
                value={controlProps.value}
                defaultValue={field.defaultValue}
                className="DatePicker no-animation"
                variant="inline"
                inputVariant="outlined"
                format="dd-MM-yyyy"
                margin="normal"
                id={"date_picker_" + name}
                disablePast={field.disablePast}
                disableFuture={field.disableFuture}
                autoOk
                minDateMessage={""}
                PopoverProps={{
                  anchorEl: () => dateRef.current as Element,
                }}
                TextFieldComponent={(props) => (
                  <TextField
                    {...props}
                    {...field}
                    ref={dateRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            src={CalendarIcon}
                            alt="DatePicker Icon"
                            width={15}
                            height={15}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
            defaultValue={field.defaultValue}
            control={control}
            name={name}
          />
        );

      case FormComponent.TimePicker:
        return (
          <Controller
            render={({
              field: controlProps,
              fieldState: { invalid, isDirty, isTouched, error },
            }) => (
              <TimePicker
                key={name}
                className="TimePicker center-align no-animation"
                variant="inline"
                inputVariant="outlined"
                format="HH:mm"
                minutesStep={5}
                margin="normal"
                ampm={false}
                id={"date_picker_" + name}
                label={label}
                autoOk
                minDateMessage={""}
                {...controlProps}
                PopoverProps={{
                  anchorEl: () => timeRef.current as Element,
                }}
                TextFieldComponent={(props) => (
                  <TextField
                    {...props}
                    {...field}
                    name={name}
                    ref={timeRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ExpandMoreIcon className="DropdownIcon" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
            defaultValue={field.defaultValue}
            control={control}
            name={name}
          />
        );

      case FormComponent.CheckBox:
      case FormComponent.Toggle:
        const isCheckBox = component === FormComponent.CheckBox;
        return (
          <Controller
            render={({
              field: controlProps,
              fieldState: { invalid, isDirty, isTouched, error },
            }) => (
              <div
                className={`${isCheckBox ? "checkbox-grid" : "ToggleCheckbox"}`}
              >
                <FormControlLabel
                  control={
                    <label className="container checkbox-label">
                      <input {...field} {...controlProps} />
                      <span className="checkmark"></span>
                    </label>
                  }
                  label={label}
                  classes={{ label: classes.checkBoxLabel }}
                  className={classes.checkBox}
                />
                <FormHelperText id={"autocomplete_helpertext_" + name} error>
                  {t(error?.message || "") || getError(name)}
                </FormHelperText>
              </div>
            )}
            control={control}
            name={name}
            key={name}
          />
        );

      case FormComponent.AddressAutoComplete:
        return (
          <Controller
            render={({
              field: controlProps,
              fieldState: { invalid, isDirty, isTouched, error },
            }) => (
              <GoogleAutocomplete
                autoComplete={field.autocomplete}
                variant="outlined"
                required
                fullWidth
                id={field.name}
                label={field.label}
                error={t(error?.message || "") || getError(name)}
                helperText={t(error?.message || "") || getError(name)}
                {...controlProps}
                {...field}
                onAddressChange={(address) => setValue(name, address)}
                onPostCodeChange={(postCode) =>
                  setValue("field_order_address_postal_code", postCode)
                }
              />
            )}
            defaultValue={field.defaultValue}
            control={control}
            name={name}
          />
        );
    }
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form onSubmit={handleSubmit((data) => onSubmit(data, setError))} className={formClassName}>
          {form.components.map((field, idx) => renderField(field, idx))}
          {children}
          {!renderButton && (
            <div className="button-container">
              <ResponseHandler />
              <ButtonWithLoader
                type="submit"
                color="primary"
                aria-label={form.submitLabel}
                loading={loading}
                success={success}
                className="submit-button"
              >
                {form.submitLabel}
              </ButtonWithLoader>
            </div>
          )}
          {renderButton && renderButton(handleSubmit((data) => onSubmit(data)))}
        </form>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default DynamicForm;
