/* eslint-disable @typescript-eslint/no-array-constructor */
import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../../assets/scss/Toolbox/MyProgress/MyProgress.scss";
import Footer from "../../../component/Footer/Footer";
import PageContainer from "../../../component/PageContainer/PageContainer";
import { Tabs } from "../../../component/Tabs/Tabs";
import ToolboxPdf from "../../../component/ToolboxPdf/ToolboxPdf";
import {MyProgressPageType, PageType} from "../../../interfaces/Pages";
import { ToolBoxData, ToolBoxDataType } from "../../../interfaces/ToolBoxData";
import { contentState, toolBoxState } from "../../../store/Reducer";
import GoalEditForm from "../Goal/GoalEditForm";
import MedicationEditForm from "../Medication/MedicationEditForm";
import { ToolBoxCard } from "./ToolBoxCard";
import { ToolboxList } from "./ToolboxList";
import { useTranslation } from "react-i18next";


interface GroupedToolboxItem {
  today: Array<any>;
  comingUp: Array<any>;
  past: Array<any>;
}

interface MyProgressPageProps {
  goalsLink: string;
  medicationsLink: string;
  reminderLink: string;
}


export default function MyProgressPage({
  goalsLink,
  medicationsLink,
  reminderLink
} : MyProgressPageProps) {

  const content: MyProgressPageType = useSelector(contentState)[PageType.myProgress];

  const toolbox: ToolBoxData = useSelector(toolBoxState);

  const [value, setValue] = useState(0);
  const [medications, setMedications] = useState<GroupedToolboxItem>({
    today: [],
    comingUp: [],
    past: [],
  });
  const [goals, setGoals] = useState<GroupedToolboxItem>({
    today: [],
    comingUp: [],
    past: [],
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isMinimal, setIsMinimal] = useState(false);
  const { t } = useTranslation();
  
  const cards = [
    {
      dataType: ToolBoxDataType.GOALS,
      link: goalsLink,
      ctaText: t("myProgress.rightSide.goals.button.label"),
      text: t("myProgress.rightSide.goals.text"),
      title: t("myProgress.rightSide.goals.title"),
    },
    {
      dataType: ToolBoxDataType.MEDICATIONS,
      link: medicationsLink,
      ctaText: t("myProgress.rightSide.medications.button.label"),
      text: t("myProgress.rightSide.medications.text"),
      title: t("myProgress.rightSide.medications.title"),
    },
    {
      dataType: ToolBoxDataType.REMINDER,
      link: reminderLink,
      ctaText: null,
      text: null,
      title: t("myProgress.rightSide.reminders.title"),
    },
  ];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setMedications(splitByDate(toolbox.medications, "startingDate"));
    setGoals(
      splitByDate(
        toolbox.goals.filter(
          (goal: any) => goal.status === "0" || goal.status === 0
        ),
        "date"
      )
    );
    
  const minimalSetting = process.env.REACT_APP_SETTING_PROGRESS_MINIMAL;
  setIsMinimal(minimalSetting === 'true');
  }, [toolbox.medications, toolbox.goals]);

  const tabLabels = [
    t("myProgress.tabs.label.today"),
    t("myProgress.tabs.label.comingUp"),
  ];
  
  return (
    <>
      <PageContainer dataType="my-progress">
        {content && (
          <>
            {!isMinimal && (
              <div className="left-side">
                <Box className={`toolbox-list`} mb={{ sm: 6, lg: 0 }}>
                  <Box
                    mt={{ sm: 0, lg: 10 }}
                    mb={{ sm: 0, lg: 6 }}
                    mx={{ sm: 0, lg: 0 }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      labels={tabLabels}
                    />
                  </Box>
                </Box>
                {value === 0 && (
                  <>
                    <ToolboxList
                      valueState={value}
                      data={medications.today}
                      dateKey="startingDate"
                      {...content.medications}
                      dataType={"medication"}
                    >
                      {(id, onClose) => (
                        <MedicationEditForm onClose={onClose} id={id} />
                      )}
                    </ToolboxList>
                    <ToolboxList
                      valueState={value}
                      data={goals.today}
                      dateKey="date"
                      {...content.goals}
                      dataType={"goal"}
                    >
                      {(id, onClose) => (
                        <GoalEditForm onClose={onClose} id={id} />
                      )}
                    </ToolboxList>
                  </>
                )}
                {value === 1 && (
                  <>
                    <ToolboxList
                      valueState={value}
                      data={medications.comingUp}
                      dateKey="startingDate"
                      {...content.medications}
                      dataType={"medication"}
                    >
                      {(id, onClose) => (
                        <MedicationEditForm onClose={onClose} id={id} />
                      )}
                    </ToolboxList>
                    <ToolboxList
                      valueState={value}
                      data={goals.comingUp}
                      dateKey="date"
                      {...content.goals}
                      dataType={"goal"}
                    >
                      {(id, onClose) => (
                        <GoalEditForm onClose={onClose} id={id} />
                      )}
                    </ToolboxList>
                  </>
                )}
              </div>
            )}
            {!isMinimal && (
              <div className="right-side">
                {cards.map((c, i) => (
                  <ToolBoxCard
                    key={i}
                    dataType={c.dataType}
                    link={c.link}
                    label={c.title}
                    text={c.text}
                    ctaText={c.ctaText}
                  />
                ))}
              </div>
            )}
          </>
        )}
        <ToolboxPdf />
      </PageContainer>
      <Footer legal_number={"GL-IDA-210000766"} />
    </>
  );
}

const splitByDate = (list, key) => {
  const past = new Array();
  const today = new Array();
  const comingUp = new Array();

  const todayDate = new Date();

  list.forEach((item) => {
    const diff =
      new Date(item[key]).setHours(0, 0, 0, 0) - todayDate.setHours(0, 0, 0, 0);

    if (diff === 0) {
      today.push(item);
    } else if (diff < 0) {
      past.push(item);
    } else {
      comingUp.push(item);
    }
  });

  return { today, comingUp, past };
};
